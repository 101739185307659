import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TypingEffect from './TypingEffect';
import { padding } from '@mui/system';
import Hoverjumpdemo from '../../Demopages/hoverjumpdemo/Hoverjumpdemo';
import "./TypingEffect.css";
import BookLiveForm from './BookLiveForm/BookLiveForm';
import Logo from '../../Components/Image/lub.png';
import Symboll from '../../Components/Image/Group 632@2x.png';
import StartupSection from './StartupSection/StartupSection';
import NutShell from './NutShell/NutShell';
import Recrument from './Recrument/Recrument';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FooterMobile from '../FooterMobile/FooterMobile';

import Industry from "./Industry/Industry";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Startforfree from './Startforfree/Startforfree';
import Customer from "./Customer/Customer";
import Counter from './Counter/Counter';
import Footer from '../Footer/Footer';
// import Container from '@mui/material/Container';
import Copyright from '../Copyright/Copyright/Copyright'
import Sidebar from '../../Components/Sidebar';
import Nutshellscroll from '../../Pages/Home/NutShell/Nutshellscroll';
import Startforfreeold from './Startforfree/Startforfree-old';
import Positionfix from '../../Demopages/positionfix/Positionfix';
import Dialogform from '../Home/Dialog/Dialogform';
import CustomerResponsive from '../Home/Customer/CustomerResponsive';
import { Link } from 'react-router-dom';
import Homebanner from "../Startup/Startupfirst/Homebanner";
import Form_demo from '../../Demopages/Form_demo/Form_demo';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (

        <>
            <Sidebar />


            <Container style={{ paddingTop: "150px" }}>


                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            {/* <img width={200} src={Symboll} /> */}
                            <br className='display-none' />
                            <TypingEffect />

                            <section className='normalfont' >
                                <div>
                                    <p>
                                       
                                    We introduce ourselves as manufacturers of Industrial Speciality Lubricants under the brand name FINE KUT. The range
includes different grades of Water Soluble, Straight (Neat Type ), Synthetic and Semi- Synthetic Coolants, Rust Preventives,
Gear Oils, Hydraulic Oils, General Purpose Lubrication Oils, Engine Oils which are blended with high performance
additives to offer improved performance.
We are also engaged in manufacturing tailor made Lubricants and Rust Preventives as per our customers requirements.
Beginning our journey in 1992, we have been providing our esteemed customers friction-free solutions and are getting
better and bigger each day as we believe the learning process never ends .
Our R&D team has been constantly upgrading our in-house facilities, infrastructure and products . So we come up with the
most precisely blended outputs which in turn provide our end users with the following benefits.
                                    </p></div></section>
                            {/* <Typography variant="h6" gutterBottom>
                            Lorem ipsum <br /> dolor sit <br />consectetur adipisicing
                        </Typography> */}
                        </Grid>
                        
                        
                    </Grid>
                        
                    
                    
                    <section className=''>
                        <div>
                            <h1 className='textaligncenter'>SPECIALITY LUBRICANTS & METAL WORKING LUBRICANTS</h1>
                            <p className='textsizeparagraph'>

                                Easy and Simple Tool to manage your team, right from Onboarding to Exit process and Attendance to one click Salary Generation
                            </p>
                            {/* <p className='textsizeparagraph'>  In publishing and graphic </p> */}

                        </div>
                    </section>
                    {/* <section className='aligncenter'>
                        <div >
                        <span style={{ display: "flex" }}>  <img style={{ height: "40px", textAlign: "center" }} src={Logo} />
                            <h1 style={{ marginLeft: "20px", }} className="Startup">for Startups</h1> </span>
                        </div>
                    </section> */}
                </Box>
            </Container>

            {/* <StartupSection /> */}
            <Container style={{ margin: "auto", textAlign: "center" }}>

                {/* <section className='aligncenter'>
                    <span style={{ display: "flex" }}>  <img className="logohrverse" src={Logo} />
                        <h1 style={{ marginLeft: "20px" }} className="Startup">At a Glance </h1> </span>
                </section> */}

            </Container>


            {/* <NutShell /> */}
            <Nutshellscroll />


            <Container style={{ margin: "auto", textAlign: "center" }}>
                <section className='aligncenter'>
                    <span style={{ display: "flex" }}>  <img className="logohrverse" src={Logo} />
                        <h1 style={{ marginLeft: "20px", }} className="Startup">for Startups</h1> </span>
                </section>
            </Container>



            <StartupSection />

            {/* <Homebanner /> */}


            <Container style={{ margin: "auto", textAlign: "center" }}>

                <section className='aligncenter ' style={{ marginBottom: "-60px" }}>
                    {/* <div > */}
                    <span style={{ display: "flex" }}>  <img className="logohrverse" src={Logo} />
                        <h1 style={{ marginLeft: "20px", }} className="Startup">Key Features</h1> </span>
                    {/* </div> */}
                </section>
            </Container>
            <Recrument />

            <Container style={{ margin: "auto", textAlign: "center" }}>

                <section className=''>
                    <div>
                        <h1 className='toiler'> BEST FIT    <span className='spancolor'>For Your Industry</span> </h1>
                    </div><br></br>
                </section>
                <Industry />
                {/* <Form_demo /> */}

            </Container>

            <br />


            <Positionfix />
            {/* <div className='Startforfreecontainer'  > */}
            {/* <Startforfree /> */}
            {/* <Hoverjumpdemo /> */}
            {/* <Startforfreeold /> */}
            {/* </div> */}
            <br />
            {/* <Container className=''> */}
            {/* <hr className='horizontalline' /> */}
            {/* </Container> */}
            <div className='display-none-form'>
                <Customer />
            </div>
            <div className='display-show'>
                <CustomerResponsive />
            </div>
            {/* <Container className=''> */}
            {/* <hr className='horizontalline2 horizontallinebottom' /> */}
            <hr className='horizontallinebottom' />
            {/* </Container> */}

            <div className='display-none-form'>
                <Footer />
                {/* <Container className=''> */}
                <hr className='horizontallinebottom' />
                {/* </Container> */}
                <Copyright />
            </div>
            <div className='display-show'>
                <FooterMobile />
            </div>

        </>
    );
}






