import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Startupimage from "../../../Components/Image/Group 856@2x.png"
import './StartupSection.css';
import Button from '@mui/material/Button';
import Dialogform from '../Dialog/Dialogform';


export default function SimpleContainer() {
    return (
        <React.Fragment>
            <CssBaseline />


            <br />

            <div>
                {/* <img src={Startupimage} style={{ width: "100%", height: "400px", objectFit: "fill" }} /> */}

            </div>
            {/* <Container maxWidth="sm"> */}
            {/* <Box sx={{ bgcolor: '#cfe8fc', height: '50vh' }} ></Box> */}

            {/* </Container> */}
            <div class="containers">
                <img src={Startupimage} className="startupimage" />

                <div className="centered">Speciality    &nbsp; <span style={{ textTransform: "uppercase" }} className='youfont  '> AND </span></div>
                <div className="Growthfont">  Lubricants </div>
                <div className="lowcasfont">Metal Working Lubricants
                </div>
                <div className='GrowthfontButton'
                // style={{ marginLeft: "100px" }}
                >
                    <Dialogform />

                    {/* <Button style={{backgroundColor:"#1C6BFE" }} className='GrowthfontButton' variant="contained">Book Live Demo</Button> */}

                </div>

            </div>



        </React.Fragment>
    );
}




