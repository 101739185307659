import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './Recruitment.css';
// import EmployeeeManagementimage from '../../../Components/Image/ils_05@2x.png'
// import PolicyDocumentsimage from '../../../Components/Image/ils_05.png';
import PolicyDocumentsimage from '../../../Components/Image/ils_05@2x.png';
import Servicescard from '../../Services/Servicescard/Servicescard';
import Startforfree from '../../Home/Startforfree/Startforfree';
import Customer from "../../Home/Customer/Customer";
import CustomerResponsive from "../../Home/Customer/CustomerResponsive";
import Featurestwo from './Featurestwo/Featurestwo';
// ########### imag svg Payroll 
import EmployeeeManagementimage from '../../../Components/Image/rust.jpeg';
import Payrollimg from '../../../Components/Image/Salary & Payroll (1).svg';
import LeaveImage from '../../../Components/Image/Onboarding.svg';




import Footer from '../../Footer/Footer';
import Copyright from '../../Copyright/Copyright/Copyright';
import Sidebar from '../../../Components/Sidebar';
import Symboll from '../../../Components/Image/Group 611@2x.png';
import Attendancemana from "./Attendancemana";
import Positionfix from '../../../Demopages/positionfix/Positionfix';
import FooterMobile from '../../FooterMobile/FooterMobile';
import FeaturesCardScroll from '../../../Pages/Services/Servicescard/FeaturesCardScroll/FeaturesCardScroll';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            <Sidebar />
            <div style={{ paddingTop: "130px" }} id="first_feature">

                <Container style={{ paddingTop: "25px" }} >
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} className="reverse-boxes">

                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                                <section>
                                    <div>
                                        {/* <p className='hiringparagraph'>The complete attendance management system</p> */}
                                        <h1 className='hiringboldtop'>Rust Preventive Oil </h1>
                                        <p className='hiringparagraph'> Our company is facilitated with very progressive machinery and technologies that help us in meeting urgent and huge order of our patrons in a timely way.</p>
                                    </div></section>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                                {/* <Item> */}

                                <br />
                                {/* <img width={300} height="auto" src={Symboll} className="symboll-img-recuriment" /> */}


                                <img src={EmployeeeManagementimage} alt="React Logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />


                                {/* </Item> */}
                            </Grid>

                        </Grid>
                        <br></br>
                        <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">ANTI RUST OIL DW 802</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                Rust preventives are added with water and moisture displacing additives that replace water or moisture in place of the additive and forms thin film over it. They are applied by dipping or spraying, they are most suitable in humid atmosphere. We cater to all types of RPO’s depending upon users application.</p>
                            </div>
                            <br />
                         </section>
                         <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">ANTI RUST OIL DW 902</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                Is the best solvent based rust preventive oil developed by our R & D team . It succesfully passes a salt spray test of 72 hours which goes beyond 100 hours. It coats the job by a thin and soft film of 4-5 microns which in turn provides a rust protection of one year in indoor conditions and 4-6 months in outdoor conditions (when covered/packed).</p>
                            </div>
                            <br />
                         </section>
                         <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">FANTI RUST OIL LCX 56</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                Is an oil based rust preventive oil of medium viscosity. Appearance of the product is almost transparent. It is enough to protect the job for one month from oxidation. It passes a salt spray of 48 hours.</p>
                            </div>
                            <br />
                         </section>
                        
                    </Box></Container>
                

                <Attendancemana />

                <br />



                {/* <Container className=''>
                    <hr className='horizontalline' />

                </Container> */}


                {/* <div className='display-none-form'>

                    <Customer />
                </div>
                <div className='display-show'>
                    <CustomerResponsive />
                </div> */}





                {/* <Container className=''> */}
                {/* <hr className='horizontalline2' /> */}
                <hr className='horizontallinebottom' />
                {/* </Container> */}
                <div className='display-none-form'>
                    <Footer />
                    {/* <Container className=''> */}
                    <hr className='horizontallinebottom' />
                    {/* </Container> */}
                    <Copyright />
                </div>
                <div className='display-show'>
                    <FooterMobile />
                </div>


            </div>
        </>

    );
}
